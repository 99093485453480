import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RemoteAssistanceService, VehicleService } from '@services/api.service';
import { promiseWrapper } from '@shared/_funtions/promiseWarapper.function';
import { switchMap, tap } from 'rxjs/operators';
import * as fromActions from './actions';

@Injectable()
export class AssistancesCustomerEffects {
  private filters: any;
  private shouldTrigger: boolean;

  public getData$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getAssistancesCustomerVehicles),
    tap(action => {
    }),
    switchMap(async () => {
      // const assistanceVehiclesData = await promiseWrapper(this.remoteAssistanceService
      //   .getAssistanceVehiclesList(),
      // );
      const assistanceVehiclesData = await promiseWrapper(this.vehicleService
        .getAssignedUserVehicles({}));
      return [
        fromActions.setAssistanceCustomerVehicles({ vehicles: ( assistanceVehiclesData.data || [] ) }),
      ];
    }),
    switchMap((actions) => [...actions]),
  ));


  constructor(
    private actions$: Actions,
    private store: Store,
    private remoteAssistanceService: RemoteAssistanceService,
    private vehicleService: VehicleService,
    private auth: AuthService
  ) {
    this.filters = {};
    // this.userId = undefined;
  }
}

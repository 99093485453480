import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { VehicleAlarmDto, VehicleService } from '@services/api.service';
import { MatAccordion } from '@angular/material/expansion';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { selectUser } from '@redux/index';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { localeMap } from '@models/user';

@Component({
  selector: 'app-tractor-detail-alarms',
  templateUrl: './tractor-detail-alarms.component.html',
  styleUrls: ['./tractor-detail-alarms.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TractorDetailAlarmsComponent implements OnInit {
  @Input() vehicleAlarmsActive: VehicleAlarmDto[];
  @Input() displayedColumns: string[];
  @Input() noSort = false;
  @Input() genericColumns: string[];
  @ViewChild(MatAccordion) accordion: MatAccordion;
  columnsToDisplayWithExpand = [];
  expandedElement: PeriodicElement | null;
  @Output() sort = new EventEmitter<Sort>();
  language: string;
  locale;

  constructor(private vehicleService: VehicleService, private store: Store, private translate: TranslateService) {

    this.locale = localeMap[this.translate.currentLang];
  }

  ngOnInit(): void {
    this.columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
    this.store.select(selectUser).pipe(filter(u => u)).subscribe(user => {
      this.language = user.user_metadata.language || 'en';
    });
  }

  sortTable(sort: Sort) {
    console.log('sort', sort);
    this.sort.emit(sort);
  }

  handleRowExpansion(row, event) {
    console.log('row', row);
    (this.expandedElement = this.expandedElement?.id === row.id ? null : { ...row });
    event.stopPropagation();
    if (this.expandedElement) {
      this.vehicleService.getVehicleAlarmTroubleshooting({
        sa: row.sa,
        spn: row.spn,
        fmi: row.fmi,
        vin: row.vin,
        language: this.language,
      }).subscribe(data => {
        this.expandedElement.troubleshooting = data.action;

      });
    }
  }

  isUnknown(severity) {
    return severity === 'Unknown';
  }
}

export interface PeriodicElement {
  engineHours: number;
  spn: string;
  fmi: string;
  counter: number;
  severity: string;
  id: number;
  source: string;
  alarmStartDateTime: Date;
  alarmDescription: string;
  troubleshooting?: string;
}

import { createReducer, on } from '@ngrx/store';
import * as Actions from './actions';
import * as RootActions from '../../actions';
import {
  Auth0UserDto,
  HasDealerDto,
  MaintenanceHistoryDto,
  MaintenanceStatusDto,
  SbdDto,
  VehicleAlarmDto,
  VehicleDTechInfoDto,
  VehicleDto,
  VehicleMaintenanceDto,
  VehicleParamSummaryDto,
  VehiclePositionDto,
  VehicleWarrantyDto,
} from '@services/api.service';

export type MeteoData = {
  temperature_2m: number;
  time: string;
  weather_code: number;
  relative_humidity_2m: number;
  wind_direction_10m: number;
  wind_speed_10m: number;
  interval: number;
  precipitation: string;
  monthly_precipitations: string;
}

export interface State {
  summaryData: VehicleParamSummaryDto;
  isLoading: boolean;
  vehicle: VehicleDto;
  vehicleAlarmsActive: VehicleAlarmDto[];
  vehicleMaintenance: VehicleMaintenanceDto;
  vehicleDTechInfo: VehicleDTechInfoDto;
  vehiclePosition: VehiclePositionDto;
  hasDealer: HasDealerDto;
  sbd?: SbdDto;
  vehicleMaintenanceStatus: MaintenanceStatusDto;
  vehicleOwner: Auth0UserDto;
  alarmHistoryCounter: number;
  isAlarmsLoading?: boolean;
  vehicleWarranty: VehicleWarrantyDto;
  vehicleMaintenanceHistory: MaintenanceHistoryDto[];
  meteo?: MeteoData;
}


export const initialState: State = {
  isLoading: false,
  summaryData: null,
  vehicle: null,
  vehicleAlarmsActive: [],
  vehicleMaintenance: null,
  vehicleDTechInfo: null,
  vehiclePosition: null,
  isAlarmsLoading: false,
  hasDealer: null,
  vehicleMaintenanceStatus: undefined,
  vehicleOwner: null,
  alarmHistoryCounter: 0,
  vehicleWarranty: null,
  vehicleMaintenanceHistory: null,
  meteo: null,
};

export const selectIsTractorDetailLoading = state => state.tractorDetail.isLoading;
export const selectIsActiveAlarmsLoading = state => state.tractorDetail.isAlarmsLoading;
export const selectSummaryData = state => state.tractorDetail.summaryData;
export const selectVehicle = state => state.tractorDetail.vehicle;
export const selectVehicleAlarmsActive = state => state.tractorDetail.vehicleAlarmsActive;
export const selectVehicleMaintenance = state => state.tractorDetail.vehicleMaintenance;
export const selectVehicleDTechInfo = state => state.tractorDetail.vehicleDTechInfo;
export const selectVehiclePosition = state => state.tractorDetail.vehiclePosition;
export const selectHasDealer = state => state.tractorDetail.hasDealer;
export const selectVehicleOwner = state => state.tractorDetail.vehicleOwner;
export const selectVehicleMaintenanceStatus = state => state.tractorDetail.vehicleMaintenanceStatus;
export const selectMeteo = state => state.tractorDetail.meteo;
export const selectTactorSbd = state => state.tractorDetail.sbd;
export const selectAlarmHistoryCounter = state => state.tractorDetail.alarmHistoryCounter;
export const selectVehicleWarranty = state => state.tractorDetail.vehicleWarranty;
export const selectVehicleMaintenanceHistory = state => state.tractorDetail.vehicleMaintenanceHistory;

export const tractorReducer = createReducer(
  initialState,
  on(RootActions.resetState, () => ({ ...initialState })),
  on(Actions.setTractorsDetailSummary, (state, values) => ({
    ...state,
    ...(
      Object.hasOwnProperty.call(values, 'vehiclePosition') ? { vehiclePosition: values.vehiclePosition } : {}
    ),
    ...(
      Object.hasOwnProperty.call(values, 'data') ? { summaryData: { ...state.summaryData, ...values.data } } : {}
    ),
    ...(
      Object.hasOwnProperty.call(values, 'meteo') ? { meteo: values.meteo } : {}
    ),
  })),
  on(Actions.setTractorDetailLoading, (state, { value }) => ({ ...state, isLoading: value })),
  on(Actions.setTractorActiveAlarmsLoading, (state, { value }) => ({ ...state, isAlarmsLoading: value })),
  on(Actions.setTractorMaintenanceStatus, (state, { vehicleMaintenanceStatus }) => ({ ...state, vehicleMaintenanceStatus })),
  on(Actions.setTractorMaintenanceHistory, (state, { vehicleMaintenanceHistory }) => ({ ...state, vehicleMaintenanceHistory })),
  on(Actions.setTractorActiveAlarms, (state, { vehicleAlarmsActive }) => ({ ...state, vehicleAlarmsActive })),
  on(Actions.setTractorDetail, (state, {
    vehicle,
    vehicleAlarmsActive,
    vehicleMaintenance,
    vehicleDTechInfo,
    vehicleOwner, vehicleWarranty,
    alarmHistoryCounter,
    sbd,
    hasDealer,
  }) => ({
    ...state, vehicle,
    vehicleAlarmsActive,
    vehicleMaintenance,
    vehicleDTechInfo, vehicleWarranty,
    vehicleOwner,
    sbd,
    alarmHistoryCounter,
    hasDealer,
  })),
);



import { createReducer, on } from '@ngrx/store';
import { DiagnosticRemoteAssistanceDto } from '@shared/_services/api.service';
import * as RootActions from '../../actions';
import * as Actions from './actions';


export interface State {
  assistances: DiagnosticRemoteAssistanceDto[],
  filters: any;
  status: any;
  key: string;
}


export const initialState: State = {
  assistances: null,
  filters: {
    q: '',
    isCompleted: false
  },
  status: {
    code: 'Loading',
    title: 'Loading',
    description: 'Waiting for the connection status',
    color: 'gray',
    icon: 'hourglass_bottom'
  },
  key: null
};

export const selectAssistances = state => state.remoteAssistances.assistances;
export const selectFilters = state => state.remoteAssistances.filters;
export const selectAssistanceStatus = state => state.remoteAssistances.status;
export const selectAssistanceKey = state => state.remoteAssistances.key;

export const assistancesReducer = createReducer(
  initialState,
  on(RootActions.resetState, () => ({ ...initialState })),
  on(Actions.setAssistances, (state, { value }) => ({ ...state, assistances: value })),
  on(Actions.setAssistancesFilters, (state, { filters }) => ({ ...state, filters: { ...state.filters, ...filters } })),
  on(Actions.setRemoteAssistanceStatus, (state, { status, key }) => ({ ...state, status, key })),
);



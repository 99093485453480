import { createReducer, on } from '@ngrx/store';
import { AssignedVehicleDto, DiagnosticRemoteAssistanceCustomerDto } from '@shared/_services/api.service';
import * as RootActions from '../../actions';
import * as Actions from './actions';


export interface State {
  assistanceCustomerVehicles: AssignedVehicleDto[],
  assistanceCustomerSelected: DiagnosticRemoteAssistanceCustomerDto;
}


export const initialState: State = {
    assistanceCustomerVehicles: null,
    assistanceCustomerSelected: null,
};

export const selectAssistanceCustomerVehicles = state => state.remoteAssistancesCustomer.assistanceCustomerVehicles;
export const selectAssistanceCustomerSelected = state => state.remoteAssistancesCustomer.assistanceCustomerSelected;

export const assistancesCustomerReducer = createReducer(
  initialState,
  on(RootActions.resetState, () => ({ ...initialState })),
  on(Actions.setAssistanceCustomerVehicles, (state, { vehicles }) => ({ ...state, assistanceCustomerVehicles: vehicles })),
  on(Actions.setAssistanceCustomerSelected, (state, { vehicle }) => ({ ...state, assistanceCustomerSelected: vehicle })),
);


  
import { createAction, props } from '@ngrx/store';
import { AssignedVehicleDto, VehicleDto, DiagnosticRemoteAssistanceDto } from '@services/api.service';


export const getAssistancesCustomerVehicles = createAction(
  'getAssistancesCustomerVehicles',
);

export const setAssistanceCustomerVehicles = createAction(
  'setAssistanceCustomerVehicles',
  props<{ vehicles: AssignedVehicleDto[]; }>(),
);

export const setAssistanceCustomerSelected = createAction(
  'setAssistanceCustomerSelected',
  props<{ vehicle: AssignedVehicleDto; }>(),
);
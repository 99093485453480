import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RemoteAssistanceService } from '@services/api.service';
import { promiseWrapper } from '@shared/_funtions/promiseWarapper.function';
import { switchMap, tap } from 'rxjs/operators';
import * as fromActions from './actions';

@Injectable()
export class AssistancesEffects {
  private filters: any;
  // private userId: string;
  private shouldTrigger: boolean;
  private hubConnection: HubConnection;

  public getData$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getAssistances),
    tap(action => {
    }),
    switchMap(async () => {
      this.shouldTrigger = false;
      const assistancesData = await promiseWrapper(this.remoteAssistanceService
        .getRemoteAssistanceListQ(this.filters.q, this.filters.isCompleted),
      );
      return [
        fromActions.setAssistances({ value: (assistancesData || []) }),
      ];
    }),
    switchMap((actions) => [...actions]),
  ));


  public $setFilters = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.setAssistancesFilters),
      tap(action => {
        this.filters = { ...this.filters, ...action.filters };
        if (action.filters.q !== undefined || action.filters.isCompleted !== undefined) {
          this.shouldTrigger = true;
        }
      }),
      switchMap((actions) => this.shouldTrigger ?
        [fromActions.getAssistances()]
        : []),
    );
  });

  public obtainWsRemoteAssistanceStatus$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.obtainWsRemoteAssistanceStatus),
    tap(async (action) => {
      const accessToken = await promiseWrapper(this.auth.getAccessTokenSilently());
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(`https://localhost:5003/hubs/remoteDiagnostic`, {
          accessTokenFactory: () => accessToken,
        })
        .build();
      this.hubConnection.on('ReceivedMessage', (connectionStatus, key) => {
        console.log('connectionStatus', connectionStatus);
        this.store.dispatch(fromActions.setRemoteAssistanceStatus({ status: connectionStatus, key }));
      });
      this.hubConnection.start()
        .then(() => this.hubConnection.invoke('JoinGroup', {
          vin: action.vin,
          assistanceId: action.assistanceId
        }))
        .catch((err) => console.log('error while establishing signalr connection: ' + err));
    }),
  ), { dispatch: false });

  public destroyWsRemoteAssistanceStatus$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.destroyWsRemoteAssistanceStatus),
    tap(() => {
      if (this.hubConnection) {
        this.hubConnection.stop()
          .then(() => console.log('SignalR connection stopped'))
          .catch((err) => console.log('error while stopping signalr connection: ' + err));
      }
    })
  ), { dispatch: false });


  constructor(
    private actions$: Actions,
    private store: Store,
    private remoteAssistanceService: RemoteAssistanceService,
    private auth: AuthService
  ) {
    this.filters = {};
    // this.userId = undefined;
  }
}

import { createAction, props } from '@ngrx/store';
import { DiagnosticRemoteAssistanceDto } from '@services/api.service';

export const getAssistances = createAction(
  'getAssistances',
);

export const setAssistances = createAction(
  'setAssistances',
  props<{ value: DiagnosticRemoteAssistanceDto[]; }>(),
);

export const setAssistancesFilters = createAction(
  'setAssistancesFilters',
  props<{ filters: any; }>(),
);

export const obtainWsRemoteAssistanceStatus = createAction(
  'obtainWsRemoteAssistanceStatus',
  props<{ vin: string; assistanceId: number }>(),
);

export const destroyWsRemoteAssistanceStatus = createAction(
  'destroyWsRemoteAssistanceStatus',
);

export const setRemoteAssistanceStatus = createAction(
  'setRemoteAssistanceStatus',
  props<{ status: any; key: string }>(),
);
import { createAction, props } from '@ngrx/store';
import {
  Auth0UserDto,
  DataTraffic,
  HasDealerDto,
  MaintenanceHistoryDto,
  MaintenanceStatusDto,
  SbdDto,
  VehicleAlarmDto,
  VehicleDTechInfoDto,
  VehicleDto,
  VehicleMaintenanceDto,
  VehicleParamSummaryDto,
  VehiclePositionDto,
  VehicleWarrantyDto,
} from '@services/api.service';
import { MeteoData } from '@redux/modules/tractorDetail/index';

export const setTractorsDetailSummary = createAction(
  'setTractorsDetailSummary',
  props<{

    vehiclePosition?: VehiclePositionDto,
    data?: VehicleParamSummaryDto
    meteo?: MeteoData
  }>(),
);

export const setTractorDetailLoading = createAction(
  'setTractorDetailLoading',
  props<{ value: boolean }>(),
);

export const setTractorActiveAlarmsLoading = createAction(
  'setTractorActiveAlarmsLoading',
  props<{ value: boolean }>(),
);

export const obtainTractorDetailJwt = createAction(
  'obtainTractorDetailJwt'
);

export const getTractorDetail = createAction(
  'getTractorDetail',
  props<{
    vin: string
    noPolling?: boolean
  }>(),
);

export const startPollingTractorDetail = createAction(
  'startPollingTractorDetail',
  props<{ vin: string, dataTraffic: DataTraffic }>(),
);
export const setTractorDetail = createAction(
  'setTractorDetail',
  props<{
    vehicle: VehicleDto,
    vehicleAlarmsActive: VehicleAlarmDto[],
    vehicleMaintenance: VehicleMaintenanceDto,
    vehicleDTechInfo: VehicleDTechInfoDto,
    vehicleOwner: Auth0UserDto
    vehicleWarranty: VehicleWarrantyDto
    alarmHistoryCounter: number,
    hasDealer: HasDealerDto,
    sbd?: SbdDto
  }>(),
);

export const setTractorActiveAlarms = createAction(
  'setTractorActiveAlarms',
  props<{ vehicleAlarmsActive: VehicleAlarmDto[] }>(),
);


export const getTractorMaintenance = createAction(
  'getTractorMaintenance',
  props<{ vin: string }>());
export const getTractorActiveAlarms = createAction(
  'getTractorActiveAlarms',
  props<{ vin: string }>());

export const getTractorMaintenanceHistory = createAction(
  'getTractorMaintenanceHistory',
  props<{ vin: string }>());

export const setTractorMaintenanceStatus = createAction(
  'setTractorMaintenanceStatus',
  props<{ vehicleMaintenanceStatus: MaintenanceStatusDto }>());

export const setTractorMaintenanceHistory = createAction(
  'setTractorMaintenanceHistory',
  props<{ vehicleMaintenanceHistory: MaintenanceHistoryDto[] }>());

export const stopPollingTractorDetail = createAction(
  'stopPollingTractorDetail',
);


